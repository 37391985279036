import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonContent, IonIcon, IonLoading, IonModal, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import './MultipleImageEditor.scss';
import { locale } from '../../locales/local';
import { PinturaEditor } from 'react-pintura';
// Import styles and functionalities from `pintura`
import 'pintura/pintura.css';
import { cameraService } from '../../services/cameraService';

import {
	// editor
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,
	createDefaultShapePreprocessor,
	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,
	plugin_decorate,
	markup_editor_defaults,
	plugin_decorate_locale_en_gb,
	markup_editor_locale_en_gb,
	getEditorDefaults,
	plugin_sticker,
	plugin_sticker_locale_en_gb,
	degToRad,
	createMarkupEditorToolbar,
	createMarkupEditorToolStyle,
	createMarkupEditorToolStyles,
} from 'pintura';
import SharedService, { sharedService } from '../../services/sharedService';
import { add } from 'ionicons/icons';
import { Image } from '../../components/Image';
import { ChatMediaType } from '../../services/enumService';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/actions/global';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';

const RotateIcon = './assets/icon/photo-rorate-icon.svg',
	CloseIcon = './assets/icon/close-icon-outline.svg',
	DeleteIcon = './assets/icon/delete-icon.svg',
	VideoPlayer = './assets/icon/video-player.svg',
	PhotoEditSmileIcon = './assets/icon/photo-edit-smile-icon.svg',
	PhotoEditTextIcon = './assets/icon/photo-edit-text-icon.svg',
	PhotoEditCropIcon = './assets/icon/photo-edit-crop-icon.svg',
	PhotoEditSquareIcon = './assets/icon/photo-edit-square-icon.svg',
	PhotoEditDrawIcon = './assets/icon/photo-edit-draw-icon.svg',
	RevertIcon = './assets/icon/revert-icon.svg',
	AlignLeftIcon = './assets/icon/align-left-icon.svg',
	AlignLCenterIcon = './assets/icon/align-center-icon.svg',
	AlignLRightIcon = './assets/icon/align-right-icon.svg',
	TextNormalIcon = './assets/icon/text-normal-icon.svg',
	TextSelectedIcon = './assets/icon/text-selected-icon.svg',
	editorColors = ['#FFFFFF', '#000000', '#FF3B30', '#FFCC4D', '#FF8300', '#04DB19', '#00A3FF', '#FF00C7'];

export const OpenEditorType = {
	FOR_PROFILE_GROUP_PHOTO: 'for_profile_group_photo',
	FOR_SEND_IMAGE: 'for_send_image',
	FOR_EDIT_IMAGE: 'for_edit_image',
};

export const EditingType = {
	DRAWING: 'drawing',
	STICKER: 'sticker',
	ORIGINAL: 'text',
	CROP: 'crop',
	RECTANGLE: 'rectangle',
};

interface iProps {
	show: boolean;
	openEditorType?: string;
	medias: any;
	outputFormat?: 'file' | 'imageData' | 'canvas' | undefined;
	onClose?: any;
	onSave?: any;
}

const MultipleImageEditor: React.FC<iProps> = ({ openEditorType, outputFormat = 'file', show, medias, onClose, onSave }) => {
	const [showModal, setShowModal] = useState(false);
	const { editor, onReady } = useFabricJSEditor();

	const dispatch = useDispatch();

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		if (show) {
			setShowModal(true);
		}
	}, [show]);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	let screenWidth = windowDimensions;
	let screenHeight = windowDimensions.height - 186;

	setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate, plugin_sticker);

	let isManuallySlideCHanging = false;

	const processDefaults = {
			imageReader: createDefaultImageReader(),
			imageWriter: createDefaultImageWriter({
				mimeType: 'image/webp',
				quality: 1,
				format: outputFormat,
			}),
		},
		editorDefaults = {
			...getEditorDefaults(),
			...processDefaults,
			enableButtonRevert: true,
			enableButtonExport: false,
			...plugin_finetune_defaults,
			...plugin_filter_defaults,
			...markup_editor_defaults,
			locale: {
				...locale_en_gb,
				...plugin_crop_locale_en_gb,
				...plugin_finetune_locale_en_gb,
				...plugin_filter_locale_en_gb,
				...plugin_decorate_locale_en_gb,
				...markup_editor_locale_en_gb,
				...plugin_sticker_locale_en_gb,
			},
			// shapePreprocessor: createDefaultShapePreprocessor(),
		},
		sliderRef = useRef<HTMLIonSlidesElement>(null),
		[isImageProcessing, setIsImageProcessing] = useState(false),
		[selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0),
		[mediasList, setMediasList] = useState<Array<File>>(medias),
		[fullSizeValues, setFullSizeValues] = useState<any>([]),
		[isHideEditor, setIsHideEditor] = useState(false),
		[isUndoButtonDisabled, setIsUndoButtonDisabled] = useState(true),
		[editorActiveColor, setEditorActiveColor] = useState(editorColors[0]),
		[currentEditingType, setCurrentEditingType] = useState(''),
		[loaded, setLoaded] = useState(false),
		[textSettings, setTextSettings] = useState({ color: '#fff', backgroundColor: 'transparent', textAlign: 'left', textStyle: 'regular', backgroundEnabled: false }),
		pinturaRef: any = {};

	if (openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO) {
		editorDefaults.imageCropAspectRatio = 1;
		editorDefaults.enableButtonRevert = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.enableUtils = false;
		editorDefaults.enableButtonClose = false;
		editorDefaults.enableButtonExport = false;
		editorDefaults.enableNavigateHistory = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.cropImageSelectionCornerStyle = 'hook';
		editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
	} else if (openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) {
		editorDefaults.imageCropAspectRatio = 0;
		editorDefaults.enableButtonRevert = false;
		editorDefaults.enableUtils = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.stickerStickToImage = true;
		editorDefaults.annotateEnableButtonFlipVertical = false;
		editorDefaults.shapePreprocessor = createDefaultShapePreprocessor();
		editorDefaults.class = '';

		// editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
		editorDefaults.utils = ['decorate', 'crop', 'filter', 'frame', 'sticker'];
		editorDefaults.markupEditorToolbar = createMarkupEditorToolbar([]); //'sharpie', 'text', 'rectangle'

		// Default
		if (currentEditingType === EditingType.DRAWING) {
			editorDefaults.decorateActiveTool = 'sharpie';
			editorDefaults.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb('#ffffff'), strokeWidth: 5 }) });
		}
	}

	editorDefaults.willRenderCanvas = (shapes, state) => {
		const { utilVisibility, selectionRect, lineColor } = state;
		// exit if crop utils is not visible
		if (utilVisibility.crop <= 0) return shapes;

		// shortcuts to selection rect
		const { x, y, width, height } = selectionRect,
			partVertical = height / 3,
			partHorizontal = width / 3;
		// return updated shapes
		return {
			// copy props from shapes param
			...shapes,
			// add an `ellipse` shape
			interfaceShapes: [
				...shapes.interfaceShapes,

				{
					x1: x,
					y1: y + partVertical,
					x2: x + width,
					y2: y + partVertical,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x,
					y1: y + partVertical * 2,
					x2: x + width,
					y2: y + partVertical * 2,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal,
					y1: y,
					x2: x + partHorizontal,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal * 2,
					y1: y,
					x2: x + partHorizontal * 2,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
			],
		};
	};

	useEffect(() => {
		if (mediasList && mediasList.length > 0) {
			if (!fullSizeValues || fullSizeValues.length === 0) {
				setFullSizeValues(mediasList.map((item: any, key: any) => fullSizeValues[key] || false));
			}
		}
	}, [mediasList, fullSizeValues]);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				// const element: any = document.querySelector("[title='Revert']");
				// if (element) {
				// 	var observer = new MutationObserver((mutations) => {
				// 		mutations?.forEach((mutation) => {
				// 			if (mutation.type === 'attributes') {
				// 				setIsUndoButtonDisabled(element.disabled);
				// 			}
				// 		});
				// 	});
				// 	if (observer) {
				// 		observer.observe(element, {
				// 			attributes: true, //configure it to listen to attribute changes
				// 		});
				// 	}
				// }
			}, 4000);

			setTimeout(() => {
				setLoaded(true);
			}, 500);
		} else {
			setLoaded(false);
			setIsUndoButtonDisabled(true);
			setCurrentEditingType('');
			setSelectedMediaIndex(0);
			setMediasList([]);
			setFullSizeValues([]);
		}
	}, [show]);

	const RevertButton = (
		<IonButton
			className="revert-btn"
			fill="clear"
			slot="icon-only"
			onClick={() => {
				pinturaRef[selectedMediaIndex]?.editor?.history?.undo();
			}}
		>
			<IonIcon src={RevertIcon} />
		</IonButton>
	);

	const closeCallback = useCallback(() => {
		setShowModal(false);
	}, []);

	const activateDrawingMode = useCallback(
		(canvas) => {
			if (canvas) {
				canvas.freeDrawingBrush.color = editorColors[0];
				canvas.freeDrawingBrush.width = 5;
				canvas.isDrawingMode = true;

				canvas.setDimensions(
					{
						width: pinturaRef.current?.clientWidth || window.innerWidth,
						height: pinturaRef.current?.clientHeight || window.innerHeight,
					},
					canvas.requestRenderAllBound
				);
			}
		},
		[pinturaRef]
	);
	const onCanvasReady = useCallback(
		(canvas, imgUrl) => {
			let devicePixelRatio = window.devicePixelRatio > 1 ? 1 : window.devicePixelRatio;

			fabric.Image.fromURL(imgUrl, (img: any) => {
				canvas.add(img);
				const editorContainerEl = document.getElementById('sliderContainerMIE');
				const containerHeight = editorContainerEl?.clientHeight || window.innerHeight;
				const containerWidth = window.innerWidth;

				const aspectRatio = img.width / img.height;

				let imgWidth = img.width;
				let imgHeight = img.height;

				// If image size is less then available screen size
				if (imgWidth < containerWidth && imgHeight < containerHeight) {
					if (imgWidth < containerWidth) {
						imgWidth = containerWidth;
						imgHeight = containerWidth / aspectRatio;
					}

					if (imgHeight < containerHeight) {
						imgWidth = containerHeight * aspectRatio;
						imgHeight = containerHeight;
					}
				}
				// If image size is greater then available screen size

				if (imgWidth > containerWidth || imgHeight > containerHeight) {
					if (imgWidth > containerWidth) {
						imgWidth = containerWidth;
						imgHeight = containerWidth / aspectRatio;
					}

					if (imgHeight > containerHeight) {
						imgWidth = containerHeight * aspectRatio;
						imgHeight = containerHeight;
					}
				}
				const left = (containerWidth - imgWidth) / 2;
				const top = (containerHeight - imgHeight) / 2;

				img.set({
					left: 0,
					top: 0,
					selectable: false,
				});
				img.scale(imgWidth / img.width);

				canvas.cacheCanvasEl.style.top = top + 'px';

				canvas.setDimensions({
					width: imgWidth * devicePixelRatio,
					height: imgHeight * devicePixelRatio,
				});
				canvas.renderAll();
			});
			onReady(canvas);
			activateDrawingMode(canvas);
			canvas.setBackgroundColor('#ffffff', canvas.requestRenderAllBound);

			canvas.on('after:render', () => {
				// info('after:render');
				// if (historyArr.length > 0) {
				// setIsChangesMade(true);
				// } else {
				// setIsChangesMade(false);
				// }
			});
			canvas.on('object:added', (object: any) => {
				// info('object:added');
				// historyArr.push({ type: 'object', value: object.target });
				// setHistoryArr(historyArr);
			});

			canvas.on('selection:cleared', () => {
				// info('selection:cleared');
				// setIsDeleteButtonShow(false);
			});
			canvas.on('selection:created', (event: any) => {
				// info('selection:created');
				// updateControlVisibility(canvas);
				// setIsDeleteButtonShow(true);
			});
			canvas.on('selection:updated', (event: any) => {
				// updateControlVisibility(canvas);
				// info('selection:updated');
			});

			// Controls UI customise
			fabric.Object.prototype.transparentCorners = false;
			fabric.Object.prototype.cornerColor = '#ffffff';
			fabric.Object.prototype.cornerStrokeColor = '#FF5E5E';
			fabric.Object.prototype.borderColor = '#FF5E5E';
			// fabric.Object.prototype.strokeWidth = controlsStrokeWidth;
			fabric.Object.prototype.cornerStyle = 'circle';
			// fabric.Object.prototype.cornerSize = controlsCornerSize;
		},
		[activateDrawingMode, onReady]
	);
	const updateColor = useCallback(
		(color) => {
			switch (currentEditingType) {
				case EditingType.DRAWING:
					if (editor?.canvas) {
						const canvas = editor?.canvas;
						console.log('canvas', canvas);
						canvas.freeDrawingBrush.color = color;
					}
					break;
				// case EditingType.BACKGROUND:
				// 	if (editor?.canvas) {
				// 		const canvas: any = editor?.canvas;
				// 		canvas.setBackgroundColor(color, canvas.requestRenderAllBound);
				// 		historyArr.push({ type: 'background', value: color });
				// 		setHistoryArr(historyArr);
				// 	}
				// 	break;
				// case EditingType.TEXT:
				// 	if (editor?.canvas) {
				// 		const canvas = editor?.canvas;
				// 		fabric.Object.prototype.objectCaching = false;

				// 		var currentlySelected = canvas.getActiveObject();
				// 		if (currentlySelected) {
				// 			if (textBgChangeActive) {
				// 				currentlySelected.backgroundColor = color;
				// 			} else {
				// 				currentlySelected.fill = color;
				// 			}
				// 			canvas.requestRenderAll();
				// 		}
				// 	}
				// 	break;

				default:
					break;
			}
		},
		[editor, currentEditingType]
	);

	const stopEditing = useCallback(() => {
		const canvas = editor?.canvas;
		if (canvas) {
			debugger;
			canvas.isDrawingMode = false;

			const downlaodImg = canvas.toDataURL({
				format: 'jpeg',
				quality: 0.95,
				multiplier: 1,
			});
			var file = SharedService.dataURLtoFile(downlaodImg, 'updatedimage.png');

			mediasList[selectedMediaIndex] = file;
			setMediasList([...mediasList]);
		}

		// setIsEditing(false);
		setCurrentEditingType('');
	}, [editor]);

	return (
		<IonModal key="multipleImageEditorModal" isOpen={showModal} onDidDismiss={onClose} swipeToClose={false} showBackdrop={false} cssClass="multiple-image-editor-modal">
			<div className="modal-content-container">
				{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
					<>
						<IonToolbar className="top-toolbar">
							<IonButtons slot="start">
								<IonButton fill="clear" slot="icon-only" onClick={closeCallback}>
									<IonIcon src={CloseIcon}></IonIcon>
								</IonButton>
							</IonButtons>

							<IonTitle>
								{currentEditingType === '' && (
									<IonButton
										className="full-image-btn"
										fill="clear"
										onClick={() => {
											fullSizeValues[selectedMediaIndex] = !fullSizeValues[selectedMediaIndex];
											setFullSizeValues([...fullSizeValues]);
										}}
									>
										Full Size
										<IonCheckbox checked={fullSizeValues ? fullSizeValues[selectedMediaIndex] : false} />
									</IonButton>
								)}
							</IonTitle>

							<IonButtons slot="end">
								{currentEditingType === '' && (
									<IonButton
										className="add-more-media-btn"
										fill="clear"
										onClick={() => {
											cameraService.chooseMediaFromAlbum((medias: Array<File>) => {
												const newList = [...mediasList, ...medias];
												if (newList.length > sharedService.maxMediaSelectLimit) {
													dispatch(
														showAlert({
															alertMessage: `Select a Maximum of ${sharedService.maxMediaSelectLimit} photos`,
														})
													);
												} else {
													setMediasList(newList);
													setSelectedMediaIndex(newList.length - 1);
												}
											}, true);
										}}
									>
										<IonIcon src={add} />
									</IonButton>
								)}

								{currentEditingType !== '' && !isUndoButtonDisabled && RevertButton}
							</IonButtons>
						</IonToolbar>

						{currentEditingType === EditingType.ORIGINAL && (
							<IonToolbar className="top-toolbar photoedit-text-toolbar">
								<IonButtons slot="start">{!isUndoButtonDisabled && currentEditingType !== '' && RevertButton}</IonButtons>

								<IonTitle>
									<IonButton
										className="text-align-btn"
										onClick={() => {
											if (textSettings.textAlign === 'left') {
												textSettings.textAlign = 'center';
											} else if (textSettings.textAlign === 'center') {
												textSettings.textAlign = 'right';
											} else if (textSettings.textAlign === 'right') {
												textSettings.textAlign = 'left';
											}
											setTextSettings({ ...textSettings });
										}}
									>
										{textSettings.textAlign === 'left' && <IonIcon src={AlignLeftIcon}></IonIcon>}
										{textSettings.textAlign === 'center' && <IonIcon src={AlignLCenterIcon}></IonIcon>}
										{textSettings.textAlign === 'right' && <IonIcon src={AlignLRightIcon}></IonIcon>}
									</IonButton>
									<IonButton className="text-style-change-btn">Regular</IonButton>
								</IonTitle>

								<IonButtons slot="end">
									<IonButton
										className="action-btn done-btn"
										slot="end"
										onClick={() => {
											setIsImageProcessing(true);
											pinturaRef[selectedMediaIndex]?.editor?.processImage();
										}}
									>
										{locale.global.done}
									</IonButton>
								</IonButtons>
							</IonToolbar>
						)}
					</>
				)}

				<div
					className={'editor-container'}
					onClick={() => {
						setIsHideEditor(!isHideEditor);
					}}
				>
					{currentEditingType === '' && mediasList && mediasList.length > 1 && (
						<div className="media-list-container">
							{mediasList &&
								mediasList.map((media: File, key: any) => {
									return (
										<div
											className={'item ' + (selectedMediaIndex === parseInt(key) ? 'active' : '')}
											key={key}
											onClick={() => {
												isManuallySlideCHanging = true;
												const slidesElement = sliderRef.current;
												if (slidesElement) {
													slidesElement.slideTo(parseInt(key), 0).then(() => {
														isManuallySlideCHanging = false;
													});
												}
												setSelectedMediaIndex(parseInt(key));
											}}
										>
											{media.type.includes(ChatMediaType.IMAGE) && (
												<>
													<Image file={media} />
													<div className="mask-view" />
												</>
											)}
											{media.type.includes(ChatMediaType.VIDEO) && (
												<>
													<video>
														<source src={URL.createObjectURL(media)}></source>
													</video>
													<div className="mask-view" />
													<IonIcon className="video-player-icon" src={VideoPlayer} />
												</>
											)}
										</div>
									);
								})}
						</div>
					)}

					{mediasList && mediasList.length > 0 && (
						<div className="slider-container" id="sliderContainerMIE">
							<IonSlides
								ref={sliderRef}
								options={{ initialSlide: selectedMediaIndex }}
								key={mediasList.map((item: File, key: any) => key).join('_')}
								onIonSlideDidChange={(event) => {
									if (!isManuallySlideCHanging) {
										const target: any = event.target;
										target.getActiveIndex().then((value: any) => setSelectedMediaIndex(value));
									}
								}}
							>
								{mediasList.map((media: File, key: any) => {
									return (
										<IonSlide key={key}>
											<div className="slider-div">
												{/* <IonImg src={media} /> */}
												{loaded && (
													<>
														{media.type.indexOf('video') !== -1 ? (
															<>
																<video controls width={screenWidth.width} height={screenHeight}>
																	<source src={URL.createObjectURL(media)}></source>
																</video>
															</>
														) : (
															<>
																{currentEditingType === EditingType.DRAWING && key === selectedMediaIndex ? (
																	<FabricJSCanvas
																		className="sketchpad-canvas"
																		onReady={(canvas) => {
																			onCanvasReady(canvas, URL.createObjectURL(media));
																		}}
																	/>
																) : (
																	<img src={URL.createObjectURL(media)} />
																)}
																{/* <PinturaEditor
																	// disabled={currentEditingType === ''}
																	{...editorDefaults}
																	className="editor"
																	ref={(ref) => {
																		pinturaRef[key] = ref;
																	}}
																	src={media}
																	onUpdateshape={() => {}}
																	onRevert={() => {
																		document.getElementById('undoBtn')?.setAttribute('disabled', 'true');
																	}}
																	onLoad={(res: any) => {
																		pinturaRef[selectedMediaIndex]?.editor?.on('update', () => {
																			setIsUndoButtonDisabled(false);
																			document.getElementById('undoBtn')?.setAttribute('disabled', 'false');
																		});
																	}}
																	onProcess={(result: any) => {
																		const file = result?.dest;
																		setIsImageProcessing(false);

																		mediasList[selectedMediaIndex] = file;
																		setMediasList([...mediasList]);

																		pinturaRef[selectedMediaIndex]?.editor?.close && pinturaRef[selectedMediaIndex].editor.close();
																		// onSave && onSave(file, isFullImage);
																	}}
																/> */}
															</>
														)}
													</>
												)}
											</div>
										</IonSlide>
									);
								})}
							</IonSlides>
						</div>
					)}
				</div>

				{openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO && (
					<div className="bottom-view-for-croponly">
						<div className="tool-containers">
							<IonButton
								className="rotate-icon"
								fill="clear"
								slot="icon-only"
								onClick={() => {
									const editorRef = pinturaRef[selectedMediaIndex]?.editor;
									if (editorRef) {
										editorRef.imageRotation = (editorRef.imageRotation || 0) - degToRad(90);
									}
								}}
							>
								<IonIcon src={RotateIcon} />
							</IonButton>
						</div>
						<IonToolbar className="save-cancel-btns">
							<IonButtons slot="start">
								<IonButton
									slot="end"
									className="action-btn edit-cancel-btn"
									onClick={() => {
										pinturaRef[selectedMediaIndex]?.editor?.close && pinturaRef[selectedMediaIndex].editor.close();
										closeCallback();
									}}
								>
									{locale.global.cancel}
								</IonButton>
							</IonButtons>
							<IonTitle>
								<IonButton
									disabled={isUndoButtonDisabled}
									fill="clear"
									mode="ios"
									id="undoBtn"
									className="action-btn undo-btn"
									onClick={() => {
										pinturaRef[selectedMediaIndex]?.editor?.history?.revert();
									}}
								>
									{locale.global.undo}
								</IonButton>
							</IonTitle>
							<IonButtons slot="end">
								<IonButton
									className="action-btn edit-save-btn"
									slot="end"
									onClick={() => {
										setIsImageProcessing(true);
										pinturaRef[selectedMediaIndex]?.editor?.processImage();
									}}
								>
									{locale.global.done}
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</div>
				)}

				{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
					<div className="bottom-tools-view">
						{currentEditingType === '' && mediasList && mediasList.length > 1 && (
							<div className="delte-btn-container">
								<IonButton
									fill="clear"
									onClick={() => {
										mediasList.splice(selectedMediaIndex, 1);
										fullSizeValues.splice(selectedMediaIndex, 1);
										if (selectedMediaIndex >= mediasList.length) {
											setSelectedMediaIndex(mediasList.length - 1);
										}
										setFullSizeValues([...fullSizeValues]);
										setMediasList([...mediasList]);
										if (mediasList.length === 0) {
											closeCallback();
										}
									}}
								>
									<IonIcon src={DeleteIcon} />
								</IonButton>
							</div>
						)}

						{currentEditingType === EditingType.DRAWING && (
							<div className="colors-container">
								{editorColors.map((color, key) => (
									<div
										onClick={() => {
											setEditorActiveColor(color);
											updateColor(color);
										}}
										className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
										key={key}
										style={{ backgroundColor: color }}
									></div>
								))}
							</div>
						)}

						{currentEditingType === EditingType.ORIGINAL && (
							<div className="colors-container">
								<div
									className="t-select"
									onClick={() => {
										textSettings.backgroundEnabled = !textSettings.backgroundEnabled;
										setTextSettings({ ...textSettings });
									}}
								>
									<IonIcon src={textSettings.backgroundEnabled ? TextSelectedIcon : TextNormalIcon} />
								</div>
								{editorColors.map((color, key) => (
									<div
										onClick={() => {
											setEditorActiveColor(color);
											const editor = pinturaRef[selectedMediaIndex]?.editor;
											setTimeout(() => {
												editor.decorateActiveTool = 'text';
												editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('text', { color: SharedService.hexToRgb(color) }) });
											}, 500);
										}}
										className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
										key={key}
										style={{ backgroundColor: color }}
									></div>
								))}
							</div>
						)}

						<div className="container">
							<div className="edit-options">
								<IonButton
									fill="clear"
									className={'edit-type-btn drawing-btn' + (currentEditingType === EditingType.DRAWING || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
										// 	// const editor = pinturaRef[selectedMediaIndex]?.editor;
										// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
										// 	decorateElement && decorateElement.click();

										// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
										// 	PinturaControlPanelsElement.style.opacity = 0;
										// 	setTimeout(() => {
										// 		const sharpieElement: any = document.querySelector('.PinturaControlListOption input[value="sharpie"]');
										// 		sharpieElement && sharpieElement.click();
										// 		sharpieElement && sharpieElement.focus();
										// 		PinturaControlPanelsElement.style.opacity = 1;
										// 	}, 100);
										// } else {
										// }

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
										setCurrentEditingType(EditingType.DRAWING);
									}}
								>
									<IonIcon style={{ width: 20, height: 25 }} src={PhotoEditDrawIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.STICKER || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
										// 	const element: any = document.querySelector('li[id$="sticker"] button');
										// 	element && element.click();
										// }
										const editor = pinturaRef[selectedMediaIndex]?.editor;
										editor.decorateActiveTool = 'sticker';
										editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sticker: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb(editorColors[0]), strokeWidth: 5 }) });

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
										setCurrentEditingType(EditingType.STICKER);
									}}
								>
									<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditSmileIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.ORIGINAL || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
										// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
										// 	decorateElement && decorateElement.click();

										// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
										// 	PinturaControlPanelsElement.style.opacity = 0;
										// 	setTimeout(() => {
										// 		const textElement: any = document.querySelector('.PinturaControlListOption input[value="text"]');
										// 		textElement && textElement.click();
										// 		textElement && textElement.focus();
										// 		PinturaControlPanelsElement.style.opacity = 1;
										// 	}, 100);
										// } else {
										// }
										const editor = pinturaRef[selectedMediaIndex]?.editor;
										editor.decorateActiveTool = 'text';
										editor.markupEditorToolStyles = createMarkupEditorToolStyles({ text: createMarkupEditorToolStyle('text', { color: SharedService.hexToRgb(editorColors[0]), fontSize: 30 }) });
										setCurrentEditingType(EditingType.ORIGINAL);
										setEditorActiveColor(editorColors[0]);

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
									}}
								>
									<IonIcon style={{ width: 32, height: 24 }} src={PhotoEditTextIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.CROP || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
											const element: any = document.querySelector('li[id$="crop"] button');
											element && element.click();
										} else {
										}
										const element: any = document.querySelector('li[id$="crop"] button');
										element && element.click();
										setCurrentEditingType(EditingType.CROP);

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
									}}
								>
									<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditCropIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.RECTANGLE || currentEditingType === '' ? ' active' : '')}
									onClick={($event) => {
										// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
										// 	// const editor = pinturaRef[selectedMediaIndex]?.editor;
										// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
										// 	decorateElement && decorateElement.click();

										// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
										// 	PinturaControlPanelsElement.style.opacity = 0;
										// 	setTimeout(() => {
										// 		const rectangleElement: any = document.querySelector('.PinturaControlListOption input[value="rectangle"]');
										// 		rectangleElement && rectangleElement.click();
										// 		rectangleElement && rectangleElement.focus();
										// 		PinturaControlPanelsElement.style.opacity = 1;
										// 	}, 100);
										// } else {
										// }
										const editor = pinturaRef[selectedMediaIndex]?.editor;
										editor.decorateActiveTool = 'rectangle';
										setCurrentEditingType(EditingType.RECTANGLE);

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
									}}
								>
									<IonIcon style={{ width: 22, height: 22 }} src={PhotoEditSquareIcon}></IonIcon>
								</IonButton>
							</div>

							{currentEditingType === '' ? (
								<IonButton
									fill="clear"
									className="send-btn"
									onClick={() => {
										// setIsImageProcessing(true);
										// pinturaRef[selectedMediaIndex]?.editor?.processImage();
										onSave && onSave(mediasList, fullSizeValues);
									}}
								>
									Send({mediasList?.length})
								</IonButton>
							) : (
								<IonButton
									fill="clear"
									className="send-btn"
									onClick={() => {
										stopEditing();
										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(false);
										pinturaRef[selectedMediaIndex]?.editor?.processImage();
									}}
								>
									Done
								</IonButton>
							)}
						</div>
					</div>
				)}

				<IonLoading isOpen={isImageProcessing} message={'Processing'} />
			</div>
		</IonModal>
	);
};

export default MultipleImageEditor;
