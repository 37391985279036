import { getInternalStorage, setInternalStorage, info, logError } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import { locale } from '../../locales/local';
import _ from 'lodash';
import striptags from 'striptags';
import { AppManager } from '../../services/appManager';
import { ChatType, SessionStorageKeys } from '../../services/enumService';
export async function createNotification(message: any = undefined, withAudio: Boolean = false) {
	const playAudio = () => {
		const audio = new Audio('/assets/alerts/notification.mp3'),
			onLoadedData = () => {
				audio.volume = 0.5;
				audio
					.play()
					.then(() => {
						info('Notifications::createNotification::playAudio audio played.');
						audio.removeEventListener('resize', onLoadedData);
					})
					.catch(() => {
						logError('Notifications::createNotification::playAudio: audio was not played.');
					});
			};

		audio.addEventListener('loadeddata', () => onLoadedData);
	};

	if (message) {
		if (getInternalStorage()[SessionStorageKeys.DesktopNotifications]) {
			// Create and show the notification
			info('Notifications::createNotification', message);

			const img: string = '/assets/icon/icon.png',
				group: any | undefined = message?.type === ChatType.GROUP ? await apiService.getGroupByJid(message.from) : undefined,
				from: any = message.from !== 'control' ? `${message.sender}${group ? `@${group.groupname}` : ''}` : 'BE-Society',
				notification: any = new Notification(locale.notification.title, {
					body: striptags(message.type !== 'control' ? `${from} ${locale.notification.says}\n${_.isObject(message.body) ? message.body?.message : message.body}` : message.body).replace(/&nbsp;/g, ' '),
					icon: img,
					image: _.isObject(message.body) ? message.body.file : undefined,
					requireInteraction: true,
					tag: message.from,
				}),
				onVisibilityChange = () => () => {
					if (document.visibilityState === 'visible') {
						// The tab has become visible so clear the now-stale Notification.
						notification.close();
						document.removeEventListener('visibilitychange', onVisibilityChange);
					}
				},
				onClick = () => {
					notification.close();
					document.removeEventListener('click', onClick);
				};

			notification.onclick = async (event: any) => {
				if (event.currentTarget.tag !== 'control') {
					window.focus();
					window.history.pushState(window.history.state, 'BE-SOCIETY', `/chat/${event.currentTarget.tag.split('@')[0]}`);
					setInternalStorage(SessionStorageKeys.Reloading, true);
					AppManager.reload();
				}

				notification.close();
			};

			document.addEventListener('visibilitychange', onVisibilityChange, { once: true });
			document.addEventListener('click', onClick, { once: true });

			if (withAudio) {
				notification.onshow = () => playAudio();
			}
		}
	} else if (withAudio) {
		playAudio();
	}
}
