import { IonModal, IonToolbar, IonButtons, IonButton, IonLabel, IonItem, IonImg, IonTextarea, IonLoading, IonThumbnail, IonIcon, IonToast } from '@ionic/react';
import React, { useCallback, useEffect, useRef } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { isJsonString, getInternalStorage, DefaultProfilePic } from '../helpers/common';
import SharedService from '../services/sharedService';
import { locale } from '../locales/local';
import { AddMembersPageActionType, SessionStorageKeys } from '../services/enumService';
import { ContactItem } from '../types/contact';
import UtilService from '../services/util';
import ToastStylish from '../components/ToastStylish';
import { checkmarkOutline } from 'ionicons/icons';

const VideoIcon = './assets/icon/video-icon.svg';
const AudioIcon = './assets/icon/audio-icon.svg';
const MessageFileBg = './assets/icon/message-file-bg.svg';
const DefaultGroupPhoto = './assets/img/blank-group-profile.png';

interface ShareMessageModalProps {
	sharingType: 'contact' | 'chat';
	show: boolean;
	shareData: any;
	contacts?: any;
	selectedContacts?: any;
	onClose?: any;
	onSend: Function;
	shareMultiTypeData?: any;
	actionType?: any;
}

const ShareMessageModal: React.FC<ShareMessageModalProps> = ({ shareMultiTypeData, sharingType, show, shareData, contacts, selectedContacts, onClose, onSend, actionType, ...props }) => {
	const forwordImg = shareMultiTypeData?.media?.mediaFile ? shareMultiTypeData?.media?.mediaFile : shareMultiTypeData;
	const sketchPadImg = sharingType === 'contact' && shareMultiTypeData;
	const forwordVideo = shareMultiTypeData?.media?.thumbnail;
	const forwordAudio = shareMultiTypeData?.media?.duration;
	const forwordText = shareMultiTypeData?.body;

	const inputRef: React.RefObject<HTMLIonTextareaElement> = useRef(null);
	const [message, setMessage] = React.useState('');

	const maxRows = 100000;
	const approxCharsPerRow = 30;
	const [allowAutoGrow, setAllowAutoGrow] = React.useState(true);
	const [isSend, setIsSend] = React.useState(false);
	const handleOnChange = React.useCallback((e) => {
		const newLines = e.target.value.split('').filter((c: any) => c === '\n').length;
		setAllowAutoGrow(newLines < maxRows - 2 && e.target.value.length < approxCharsPerRow * maxRows);
		if (!e.target.value || e.target.value.length === 0) {
			e.target.style.height = 'auto';
		} else {
			e.target.style.height = 'auto';
		}
		if (e.target.clientHeight === 60) {
			e.target.style.color = '#000000';
		} else {
			e.target.style.color = '#979797';
		}
		setMessage(e.target.value);
	}, []);
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 1000);
		}
	}, [show, inputRef]);

	const onCloseCallBack = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	const shareSendClick = useCallback(() => {
		onSend && onSend(message);
		setMessage('');
	}, [onSend, message]);

	let snippet;
	let forwordedMsg;
	if (sharingType === 'chat') {
		let msgBody = isJsonString(shareData?.body) ? JSON.parse(shareData?.body) : shareData?.body;
		const extractChatFromMsgbody = SharedService.extractChatFromMsgbody(msgBody);
		snippet = extractChatFromMsgbody.snippet;
		// forwordedMsg = snippet?._owner?.pendingProps.shareData.body;

		// let attachmentData: any = extractChatFromMsgbody.attachmentData;
		// || actionType === AddMembersPageActionType.ChooseContactWithShareContact
	} else if (sharingType === 'contact') {
		if (actionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) {
			{
				contacts &&
					contacts.map((_contact: ContactItem, key: number) => {
						const contactIndex = selectedContacts.indexOf(_contact.username);
						if (contactIndex === -1) return null;
						const selectedName = _contact.userInfo.profileName;
						return (snippet = '[Contact Card] ' + selectedName);
					});
			}
		} else {
			snippet = '[Contact Card] ' + shareData;
		}
	}

	const cookies: any = async () => getInternalStorage();

	return (
		<>
			<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass={'share-message-modal'} animated={true}>
				<div className={shareData ? 'content-view-container' : 'content-view-container-active'}>
					<div className="send">
						<IonLabel className="title-label">{locale.share_message.send_to}:</IonLabel>
					</div>

					{selectedContacts.length === 1 ? (
						<div className="contact-view">
							{contacts &&
								contacts.map((_contact: ContactItem, key: number) => {
									const contactIndex = selectedContacts.indexOf(_contact.username);
									if (contactIndex === -1) return null;

									const profilePhoto = actionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ? SharedService.extractProfilePhoto(shareMultiTypeData?.profilePhotos)?.url : SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url;
									return (
										<div className="profile-container" key={key}>
											<div className="selected-image-container">
												<IonThumbnail className="selected-image">
													<IonImg
														alt=""
														src={
															actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup
																? SharedService.fixBase64String(shareMultiTypeData.mucImage ? shareMultiTypeData.mucImage : DefaultGroupPhoto)
																: SharedService.fixBase64String(profilePhoto ? profilePhoto : DefaultProfilePic)
														}
													/>
												</IonThumbnail>
											</div>
											<div className="user-name">
												<div className="name">{actionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup ? shareData : _contact.userInfo.profileName}</div>
												{actionType !== AddMembersPageActionType.ChooseContactForShareContactInGroup && <div className="profession">{actionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ? shareMultiTypeData.profession : _contact.userInfo.profession} </div>}
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<div className={selectedContacts.length > 5 ? 'selected-contacts-forward' : 'selected-contacts-container'} id="selectedContactsContainer">
							{contacts &&
								contacts.map((_contact: ContactItem, key: number) => {
									const contactIndex = selectedContacts.indexOf(_contact.username);
									if (contactIndex === -1) return null;
									const profilePhoto = SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url || DefaultProfilePic;

									return (
										<div className="selected-contact-item" key={key}>
											<img alt="" src={SharedService.fixBase64String(profilePhoto)} />
										</div>
									);
								})}
						</div>
					)}
					{shareData && snippet !== undefined && <div className="shar-contact-username">{snippet}</div>}
					{shareMultiTypeData?.messageType === 'text' && (
						<div className="forwordedText">
							<div className="text-msg">{forwordText}</div>
						</div>
					)}

					{(typeof sketchPadImg === 'string' || shareMultiTypeData?.media?.mediaType === 'image') && (
						<div className="sketch-image">
							<IonImg className="sketch" src={forwordImg || sketchPadImg || DefaultProfilePic} />
						</div>
					)}
					{shareMultiTypeData?.media?.mediaType === 'video' && (
						<div className="video-thumbnail">
							<div className="temp">
								<img className="image-file" src={forwordVideo} alt={'N/A'} />
								<IonIcon slot="icon-only" className="video-btn" src={VideoIcon} />
							</div>
						</div>
					)}
					{shareMultiTypeData?.media?.mediaType === 'audio' && (
						<div className="audio-type-message">
							<img className="icon" src={AudioIcon} alt="media" />
							<div className="file-name">{forwordAudio ? `${UtilService.formatDuration(forwordAudio)}` : 'N/A'}</div>
						</div>
					)}
					{shareMultiTypeData?.media?.mediaType === 'document' && (
						<div className="file-type-message-container">
							<div className="file-type-message">
								<img className="file-bg" src={MessageFileBg} alt="media" />
								<div className="file-name">{shareMultiTypeData.media?.mediaFile?.split('/').pop()}</div>
								<div className="size-type-view">
									<div className="size">{UtilService.formatSize(shareMultiTypeData.media?.size || 0)}</div> | <div className="type">{shareMultiTypeData.media?.mediaFile?.split('.').pop().toUpperCase()}</div>
								</div>
							</div>
						</div>
					)}
					<IonItem lines="none">
						<IonTextarea
							mode={'md'}
							placeholder={locale.share_message.leave_message}
							rows={allowAutoGrow ? 1 : maxRows}
							autoGrow={allowAutoGrow}
							autofocus={true}
							debounce={1}
							value={String(message)}
							onKeyPress={(event) => {
								if (!cookies[SessionStorageKeys.MobileDevice]) {
									if (event.ctrlKey && event.key === 'Enter') {
										event.preventDefault();
										setMessage(message + '\n');
									} else if (event.key === 'Enter') {
										event.preventDefault();
									}
								}
							}}
							onIonChange={handleOnChange}
							ref={inputRef}
							onFocus={() => {}}
						></IonTextarea>
					</IonItem>
					<div className="share-message-bottom-bar">
						<IonButton className="cancel" fill="clear" onClick={onCloseCallBack}>
							{locale.cancel}
						</IonButton>
						<IonButton
							className="send-button"
							fill="clear"
							onClick={() => {
								setIsSend(true);
								shareSendClick();
								onCloseCallBack();
							}}
						>
							{locale.send}
							{/* {selectedContacts && selectedContacts.length > 1 ? '( ' + selectedContacts.length + ' )' : ''} */}
						</IonButton>
					</div>
				</div>
			</IonModal>
			<IonLoading isOpen={isSend} message={'Sending'} duration={300} />
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShareMessageModal);
