import React, { useMemo } from 'react';
import { DefaultProfilePic } from '../../../../helpers/common';
import { ChatType, MessageType } from '../../../../services/enumService';
import SharedService from '../../../../services/sharedService';
import { ContactItem } from '../../../../types/contact';
import { Message } from '../../../../types/message';
import { ChatStateTypes, ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../../types/redux-states';
import './style.scss';
import { connect } from 'react-redux';

type ThreadRepliedForViewProps = {
	profile?: ProfileStateTypes;
	chat?: ChatStateTypes;
	contact?: ContactStateTypes;

	alignClassName: string;
	userMessageData: Message;
	onClick: (e: any) => void;
};

const ThreadRepliedForView: React.FC<ThreadRepliedForViewProps> = ({ alignClassName, userMessageData, onClick, profile, chat, contact }) => {
	const isTextType: Boolean = useMemo(() => userMessageData?.messageType === MessageType.TEXT, [userMessageData]);
	const isGifType: Boolean = useMemo(() => userMessageData?.messageType === MessageType.GIF, [userMessageData]);
	const isMediaType: Boolean = useMemo(() => userMessageData?.messageType === MessageType.MEDIA || userMessageData?.messageType === MessageType.FILE, [userMessageData]);
	const extractChatFromMsgbody = SharedService.extractChatFromMsgbody(userMessageData);

	const message: Message = userMessageData;

	let userProfileName: string = message.from,
		userProfilePhoto = DefaultProfilePic;
	const myProfile = profile?.loggedInUserProfile;

	if (message.from === myProfile?.username) {
		userProfilePhoto = SharedService.extractProfilePhoto(myProfile?.profilePhotos || [])?.url || DefaultProfilePic;
		userProfileName = 'Me';
	} else if (chat?.type === ChatType.GROUP) {
		if (message.from !== myProfile?.username) {
			let receiverProfileDetail: ContactItem | undefined = contact?.contacts?.find((_contact: ContactItem) => _contact?.username === message.from);
			if (receiverProfileDetail) {
				userProfilePhoto = SharedService.extractProfilePhoto(receiverProfileDetail?.userInfo.profilePhotos || [])?.url || DefaultProfilePic;
				userProfileName = receiverProfileDetail.alias ?? receiverProfileDetail.userInfo.profileName;
			}
		}
	} else {
		const userProfile = chat?.receiver as ContactItem;
		userProfilePhoto = SharedService.extractProfilePhoto(userProfile?.userInfo?.profilePhotos || [])?.url || DefaultProfilePic;
		userProfileName = userProfile?.alias || userProfile?.userInfo?.profileName || userProfile?.userInfo?.username || '';
	}

	return (
		<div className={'replied-for-view ' + alignClassName} onClick={onClick}>
			<img className="profile-img" src={userProfilePhoto} alt="profilePicture" />
			<div className={'user-title-msg ' + (isMediaType || isGifType ? 'image-type' : isTextType ? 'text-type' : '')}>
				<span className="title" dangerouslySetInnerHTML={{ __html: userProfileName?.replace('(', '(<span class="paranthesis">')?.replace(')', '</span>)') + ':' }}></span>
				&nbsp;
				{isTextType && extractChatFromMsgbody.snippet}
				{isMediaType && extractChatFromMsgbody.snippet}
				{isGifType && extractChatFromMsgbody.snippet}
			</div>
		</div>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		chat: state.chat,
		contact: state.contact,
	};
};

const mapDispatchToProps = (_: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ThreadRepliedForView);
