import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonThumbnail, IonSpinner, IonButton, IonText, IonCheckbox, IonItemSliding, IonItem, IonImg } from '@ionic/react';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';
import './style.scss';
import { apiService } from '../../../../services/apiService';
import { EmojiPopup } from '../emoji-popup';
import SharedService, { sharedService } from '../../../../services/sharedService';
import moment from 'moment';
import { publishData } from '../../../../redux/actions/pubsub';
import { connect, useDispatch } from 'react-redux';
import { ChatMediaType, ChatMessageState, MessageType, MessageSendStatus, PubSubEventType, ChatType } from '../../../../services/enumService';
import { locale } from '../../../../locales/local';
import { info } from '../../../../helpers/common';
import { xmpp } from '../../../../services/xmpp';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import RecallPopupModal from '../../../../modals/RecallPopupModal';
import ThreadItemOptions from '../ThreadItemOptions';
import ThreadRepliedForView from './../ThreadRepliedForView';
import CustomSpinner from '../../../../components/CustomSpinner';
import ResendMessageModal from '../../../../modals/ResendMessageModal';
import { Message } from '../../../../types/message';
import { translateMessage } from '../../../../redux/actions/chat';
import DeleteContact from '../../../../modals/DeleteContact';
import PubSub from 'pubsub-js';
import { showStylishToast, showToast } from '../../../../redux/actions/global';
import { GlobalStateTypes, ProfileStateTypes } from '../../../../types/redux-states';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Config } from '../../../../config/config';
import axios from 'axios';
import UtilService from '../../../../services/util';
import { copyImageToClipboard, requestClipboardWritePermission } from 'copy-image-clipboard';

const gf = new GiphyFetch(Config.GIPHY_API_KEY);

require('default-passive-events');

const Trash = './assets/icon/trash.svg';
const Copy = './assets/icon/copy.svg';
const Forward = './assets/icon/forward.svg';
const ChatFevorite = './assets/icon/chatfevorite.svg';
const ChatSearch = './assets/icon/chatsearch.svg';
const Remind = './assets/icon/remind.svg';
const Tick = './assets/icon/tick-light.svg';
const Translate = './assets/icon/translate.svg';
const EMOJISMILE = './assets/icon/emoji-icon.svg';
const Editphoto = './assets/icon/edit-photo-icon.svg';

const Download = './assets/icon/download.svg';
const ExpandIcon = './assets/icon/expand.svg';
const ReplyIcon = './assets/icon/reply.svg';
const NotepadIcon = './assets/icon/notepad.svg';
const ReplyArrowIcon = './assets/icon/chat-reply.png';
const FailedIcon = './assets/icon/error.svg';
const VideoMessageIcon = './assets/icon/msg-video-icon.svg';
const Send = './assets/icon/send.svg';
const ThreadMenu = './assets/icon/thread-light.svg';

// Popup options items start
const forwardItem = {
	title: locale.dashboard.thread.forward,
	icon: Forward,
	type: 'forward',
};

const copyItem = {
	title: locale.dashboard.thread.copy,
	icon: Copy,
	type: 'copy',
};

const chatSpeakerOffItem = {
	title: locale.dashboard.thread.chatSpeakerOff,
	icon: Copy,
	type: 'speaker',
};
const favoriteItem = {
	title: locale.dashboard.thread.fevorite,
	icon: ChatFevorite,
	type: 'save',
};

const deleteItem = {
	title: locale.dashboard.thread.delete,
	icon: Trash,
	type: 'delete',
};

const selectItem = {
	title: locale.dashboard.thread.select,
	icon: Tick,
	type: 'check',
};

const expandItem = {
	title: locale.dashboard.thread.expand,
	icon: ExpandIcon,
	type: 'enlarge',
};
const remindItem = {
	title: locale.dashboard.thread.remind,
	icon: Remind,
	type: 'remind',
};

const notepadItem = {
	title: locale.dashboard.thread.notepad,
	icon: NotepadIcon,
	type: 'notepad',
};
const replyItem = {
	title: locale.dashboard.thread.reply,
	icon: ReplyIcon,
	type: 'reply',
};
const translateItem = {
	title: locale.dashboard.thread.translate,
	icon: Translate,
	type: 'translate',
};
const Thread = {
	title: locale.dashboard.thread.copy,
	icon: ThreadMenu,
	type: 'ThreadMenu',
};

const searchItem = { icon: ChatSearch, type: 'search' };
const sendItem = { icon: Send, type: 'send' };
const emojiSmileItem = { icon: EMOJISMILE, type: 'emojismile' };

const editPhotoItem = { icon: Editphoto, type: 'editphoto' };
const downloadItem = { icon: Download, type: 'download' };

//-- End Popup options items end

const recallMaxTimeInMins = 5;

type CardProps = {
	profile: ProfileStateTypes;
	showToastView: (payload: GlobalStateTypes) => void;
	onPublishData: Function;
	translateMessage: (payload: any) => Promise<any>;

	type: string;
	receiverProfilePhoto: any;
	align: string;
	message: Message;
	selectedMessages: [];
	selectingMessages: boolean;
	senderProfileName: string;
	key?: string;
	actionHandler: Function;
	onReplyItemSelect?: Function;
	startSelectingMessages?: Function;
	startForwardingMessages?: Function;
	onProfilePhotoPress?: (event: any) => void;
	opctionClick?: (optionValue: string) => void;
};

const ThreadComponent = forwardRef(
	(
		{
			profile,
			showToastView,
			onPublishData,
			translateMessage,
			receiverProfilePhoto,
			align,
			type,
			key,
			selectingMessages,
			message,
			selectedMessages,
			senderProfileName,
			startSelectingMessages = () => {},
			startForwardingMessages = () => {},
			opctionClick = () => {},
			actionHandler = () => {},
			onReplyItemSelect = (message: Message) => {},
			onProfilePhotoPress,
		}: CardProps,
		ref
	) => {
		const dispatch = useDispatch();

		const recallTimerIntervalRef = useRef();
		const ionItemSlideRef = useRef(null);
		let isGroup: boolean = type === ChatType.GROUP,
			count: number = 0,
			snippet: any,
			isEmojiStyleApplied: boolean = false,
			attachmentData: any,
			canTranslate: boolean = message.hasOwnProperty('body') ? !message.body?.translated : false,
			messageSentTime: string = message?.datetime;

		const cardRef: React.RefObject<HTMLIonCardElement> = useRef(null),
			msgCardContentRef: React.RefObject<HTMLIonCardContentElement> = useRef(null),
			[isLoading, setIsLoading] = useState<boolean>(false),
			[showResendMessageModal, setShowResendMessageModal] = useState<boolean>(false),
			[_, setIsResendingMessage] = useState<boolean>(false),
			[fileUploadingProgress, setFileUploadingProgress] = useState<number>(0),
			[shouldShowReadMoreLess, setShouldShowReadMoreLess] = useState<boolean>(false),
			[showReadMore, setShowReadMore] = useState<boolean>(false),
			[longpressPopupIsEnlarge, setLongpressPopupIsEnlarge] = useState<boolean>(false),
			[recallRemainingTimeInSeconds, setRecallRemainingTimeInSeconds] = useState<number>(0),
			[translating, setTranslating] = useState<boolean>(false),
			[actionSheetHeaderTitle, setActionSheetHeaderTitle] = useState<string>(''),
			[showActionSheet, setShowActionSheet] = useState<boolean>(false),
			[showRecallPopup, setShowRecallPopup] = useState<boolean>(false),
			[actionsheetButtons, setActionsheetButtons] = useState<any>(null),
			[showEmojiPopover, setShowEmojiPopover] = useState<boolean>(false),
			[showQuickFunctionPopover, setShowQuickFunctionPopover] = useState<boolean>(false),
			[selectedThreadElementPosition, setSelectedThreadElementPosition] = useState<any>(null),
			[isRecall, setIsRecall] = useState<boolean>(message.recalled),
			[topRowItems, setTopRowItems] = useState<any>([]),
			[bottomRowItems, setBottomRowItems] = useState<any>([]),
			DOUBLE_CLICK_THRESHOLD = 250,
			extractChatFromMsgbody = SharedService.extractChatFromMsgbody(message),
			cardClickHandler = useCallback(
				(event) => {
					const elementPos: DOMRect = event?.currentTarget?.getBoundingClientRect();
					if (elementPos) {
						setSelectedThreadElementPosition(elementPos);
					}
					if (count === 0) {
						setTimeout(() => {
							if (count === 1) {
								// eslint-disable-next-line react-hooks/exhaustive-deps
								count = 0;

								actionHandler(attachmentData, message);
							}
							if (count > 1) {
								count = 0;
								info('Double Tap');
								if (!sharedService.isChatScrolling) {
									setShowEmojiPopover(true);
								}
							}
						}, DOUBLE_CLICK_THRESHOLD);
					}
					count++;
				},
				[count, message, attachmentData]
			),
			longPressCallback = useCallback(() => {
				sharedService.isChatScrolling = false;
				if (!sharedService.isChatScrolling) {
					// check message type and set emoji popup array data
					let topRowItemsList = [];
					let bottomRowItemsList = [];

					if (message?.messageType === MessageType.TEXT) {
						if (message.status === MessageSendStatus.SentFailed) {
							bottomRowItemsList.push(copyItem);
							bottomRowItemsList.push(expandItem);
							bottomRowItemsList.push(deleteItem);
							bottomRowItemsList.push(sendItem);
						} else {
							let msgBody: any = message.body,
								emojiiCount: any = SharedService.countEmojis(msgBody);

							let isOnlyEmoji = false;
							if (emojiiCount > 0) {
								const textString = msgBody.replace(/<[^>]*>/g, '');
								const otherText = msgBody.replace(SharedService.emojisPattern(), '');

								if (SharedService.containsOnlyEmojis(textString) || otherText.length === 0) {
									isOnlyEmoji = true;
								}
							}
							// msgBody.match(/[a-zA-Z\d]+:?(\/\/(\w+:\w+@))?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(.*)?/g)
							if (message.linkPreview) {
								topRowItemsList.push(forwardItem);
								topRowItemsList.push(copyItem);
								topRowItemsList.push(favoriteItem);
								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);

								bottomRowItemsList.push(expandItem);
								bottomRowItemsList.push(searchItem);
								bottomRowItemsList.push(remindItem);
								bottomRowItemsList.push(notepadItem);
								bottomRowItemsList.push(replyItem);
							} else if (isOnlyEmoji) {
								topRowItemsList.push(forwardItem);
								topRowItemsList.push(copyItem);
								topRowItemsList.push(favoriteItem);
								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);

								bottomRowItemsList.push(expandItem);
								bottomRowItemsList.push(remindItem);
								bottomRowItemsList.push(notepadItem);
								bottomRowItemsList.push(replyItem);
							} else {
								topRowItemsList.push(forwardItem);
								topRowItemsList.push(copyItem);
								topRowItemsList.push(favoriteItem);
								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);

								bottomRowItemsList.push(expandItem);
								bottomRowItemsList.push(remindItem);
								bottomRowItemsList.push(notepadItem);
								bottomRowItemsList.push(replyItem);
								bottomRowItemsList.push(translateItem);
							}
						}
						setTopRowItems([...topRowItemsList]);
						setBottomRowItems([...bottomRowItemsList]);
					} else if (message?.messageType === MessageType.MEDIA || message?.messageType === MessageType.FILE) {
						if (message.media?.mediaType === ChatMediaType.IMAGE) {
							if (message.status === MessageSendStatus.SentFailed) {
								bottomRowItemsList.push(copyItem);
								bottomRowItemsList.push(deleteItem);
								bottomRowItemsList.push(sendItem);
							} else {
								topRowItemsList.push(forwardItem);
								topRowItemsList.push(copyItem);
								topRowItemsList.push(favoriteItem);
								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);

								bottomRowItemsList.push(remindItem);
								bottomRowItemsList.push(downloadItem);
								bottomRowItemsList.push(notepadItem);
								bottomRowItemsList.push(replyItem);
								bottomRowItemsList.push(translateItem);
							}
						} else if (message.media?.mediaType === ChatMediaType.VIDEO || message.media?.mediaType === ChatMediaType.DOCUMENT) {
							if (message.status === MessageSendStatus.SentFailed) {
								bottomRowItemsList.push(deleteItem);
								bottomRowItemsList.push(sendItem);
							} else {
								topRowItemsList.push(forwardItem);
								topRowItemsList.push(remindItem);
								topRowItemsList.push(favoriteItem);
								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);
								if (message.media?.mediaType === ChatMediaType.VIDEO) {
									bottomRowItemsList.push(downloadItem);
								}
								bottomRowItemsList.push(notepadItem);
								bottomRowItemsList.push(replyItem);
							}
						} else if (message.media?.mediaType === ChatMediaType.AUDIO) {
							if (message.status === MessageSendStatus.SentFailed) {
								bottomRowItemsList.push(deleteItem);
								bottomRowItemsList.push(sendItem);
							} else {
								if (message.from === profile?.loggedInUserProfile?.username) {
									topRowItemsList.push(forwardItem);
								}
								topRowItemsList.push(Thread);
								topRowItemsList.push(favoriteItem);

								if (message.from !== profile?.loggedInUserProfile?.username) {
									topRowItemsList.push(remindItem);
								}

								topRowItemsList.push(deleteItem);
								topRowItemsList.push(selectItem);

								if (message.from === profile?.loggedInUserProfile?.username) {
									bottomRowItemsList.push(remindItem);
									bottomRowItemsList.push(notepadItem);
									bottomRowItemsList.push(replyItem);
								}
							}
						}
					} else if (message?.messageType === MessageType.GIF) {
						if (message.status === MessageSendStatus.SentFailed) {
							bottomRowItemsList.push(deleteItem);
							bottomRowItemsList.push(sendItem);
						} else {
							topRowItemsList.push(forwardItem);
							topRowItemsList.push(remindItem);
							topRowItemsList.push(emojiSmileItem);
							topRowItemsList.push(deleteItem);
							topRowItemsList.push(selectItem);

							bottomRowItemsList.push(notepadItem);
							bottomRowItemsList.push(replyItem);
						}
					}
					setTopRowItems([...topRowItemsList]);
					setBottomRowItems([...bottomRowItemsList]);
					setShowQuickFunctionPopover(true);
				}
			}, [message.body, message?.messageType, message.linkPreview, message.status, message.media]),
			bind = useLongPress(longPressCallback, {
				onStart: (event) => {
					const elementPos: DOMRect | undefined = event?.currentTarget?.getBoundingClientRect();

					if (elementPos) {
						setSelectedThreadElementPosition(elementPos);
					}
					info('selected message: ', selectingMessages);
				},
				onFinish: () => {},

				onCancel: () => {},
				threshold: 500,
				captureEvent: true,
				detect: LongPressDetectEvents.BOTH,
			}),
			emojiClickHandler = useCallback(
				async (action: string) => {
					switch (action) {
						case 'like':
							info('Like clickedhandler');
							break;

						case 'save':
							dispatch(
								showStylishToast({
									stylishToastMessage: 'Added',
									stylishToastIcon: 'check',
								})
							);

							break;

						case 'forward':
							info('forward clickedhandler');
							// startForwardingMessages();
							opctionClick && opctionClick('forward');

							break;
						case 'download':
							info('download clickedhandler');
							opctionClick && opctionClick('download');

							break;
						case 'notepad':
							info('notepad clickedhandler');
							opctionClick && opctionClick('notepad');

							break;
						case 'emojismile':
							if (message.messageType === MessageType.GIF) {
								const giphyId = message.body.id || message.body.giphyId; //message.body.giphyId deprecated
								let fixed_widthObj;
								if (message.body.images?.fixed_width) {
									fixed_widthObj = message.body.images.fixed_width;
								} else {
									const gifItem = await gf.gif(giphyId);
									fixed_widthObj = gifItem.data.images.fixed_width;
								}
								await SharedService.addFavGif({
									id: giphyId,
									fixed_widthObj,
								});
								showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Added to Favorites', toastType: 'secondary' });
								if (sharedService.isEmojiStickerViewOpened) {
									PubSub.publish(PubSubEventType.FavGifsUpdated, {
										action: PubSubEventType.FavGifsUpdated,
									});
								}
							}

							break;

						case 'enlarge':
							info('enlarge clickedhandler');

							if (!sharedService.isChatScrolling) {
								setShowQuickFunctionPopover(true);
								setLongpressPopupIsEnlarge(true);
							}

							break;

						case 'copy':
							if ((message.messageType === MessageType.MEDIA || message.messageType === MessageType.FILE) && message.media?.mediaType === ChatMediaType.IMAGE) {
								const response = await axios.get(message.media?.mediaFile, {
									headers: {},
									responseType: 'blob', // Important
								});

								const blob = new Blob([response.data], { type: UtilService.fileMimeTypes[UtilService.get_url_extension(message.media?.mediaFile)!] });
								const blobUrl = URL.createObjectURL(blob);

								// message.media?.mediaFile
								// Can be an URL too, but be careful because this may cause CORS errors
								requestClipboardWritePermission().then((hasPermission) => {
									if (hasPermission) {
										copyImageToClipboard(blobUrl)
											.then(() => {
												info('Image Copied');
												setTimeout(() => {
													dispatch(
														showStylishToast({
															stylishToastMessage: 'Copied',
															stylishToastIcon: 'check',
														})
													);
												}, 500);
											})
											.catch((e) => {
												info('Error: ', e.message);
											});
									} else {
										info('Clipboard write permission denied');
										dispatch(
											showToast({
												toastMessage: 'Clipboard write permission denied',
											})
										);
									}
								});
							} else {
								navigator.clipboard.writeText(message.hasOwnProperty('body') ? message.body : message);
								dispatch(
									showStylishToast({
										stylishToastMessage: 'Copied',
										stylishToastIcon: 'check',
									})
								);
							}

							// const response = await fetch(message.body);
							// const blob = await response.blob();
							// const windowRef: any = window;
							// const ClipboardItem: any = windowRef.ClipboardItem;
							// const clipboard: any = navigator.clipboard;
							// const data = [new ClipboardItem({ [blob.type]: blob })];
							// await clipboard.write(data);
							break;

						case 'delete':
							setShowActionSheet(true);
							setActionSheetHeaderTitle(locale.dashboard.thread.recall_final_confirm);
							setActionsheetButtons([
								{
									text: 'Delete',
									role: 'destructive',
									handler: async () => {
										info(`${action} clicked`);
										setShowActionSheet(false);
										setIsLoading(true);
										await apiService.deleteMessage({ ...message, deleted: 1 }, true).then(() => {
											setIsLoading(false);
										});
									},
								},
								{
									text: 'Cancel',
									handler: () => {
										setShowActionSheet(false);
										setActionsheetButtons(null);
										setActionSheetHeaderTitle('');
									},
								},
							]);
							break;

						case 'recall':
							setShowRecallPopup(true);
							break;
						case 'ThreadMenu':
							startSelectingMessages();

							opctionClick && opctionClick('threadMenu');

							break;

						case 'check':
							info(message);
							startSelectingMessages();
							opctionClick && opctionClick('check');
							break;

						case 'remind':
							info('Remind clickedhandler');
							break;

						case 'translate':
							setTranslating(true);

							const navigatorRef: any = navigator,
								currentLaguage: string = navigatorRef?.language || navigatorRef.userLanguage,
								currentLanguageCode: string = currentLaguage ? currentLaguage.split('-')[0] : 'en';

							await translateMessage({ message, currentLanguageCode });
							setTranslating(false);

							break;

						case 'reply':
							info('reply clickedhandler');

							onPublishData({
								type: PubSubEventType.ReplyToTheConversation,
								data: {
									...message,
									senderProfileName,
									userProfilePic: receiverProfilePhoto,
								},
							});

							break;
					}
					// eslint-disable-next-line react-hooks/exhaustive-deps
				},
				[message, startSelectingMessages, opctionClick, startForwardingMessages, onPublishData, senderProfileName, receiverProfilePhoto]
			),
			isSelected = selectingMessages && selectedMessages.some((msg: any) => msg.messageKey === message.messageKey);

		snippet = extractChatFromMsgbody.snippet;
		isEmojiStyleApplied = extractChatFromMsgbody.isEmojiStyleApplied;
		attachmentData = extractChatFromMsgbody.attachmentData;

		const stopRecallOption = useCallback(() => {
				if (showRecallPopup) {
					setShowRecallPopup(false);
				}
				if (recallRemainingTimeInSeconds > 0) {
					setRecallRemainingTimeInSeconds(0);
				}
				recallTimerIntervalRef && clearInterval(recallTimerIntervalRef.current);
			}, [showRecallPopup, recallRemainingTimeInSeconds, recallTimerIntervalRef]),
			getRemainingTime = useCallback(() => {
				const diffSecs = recallMaxTimeInMins * 60 - moment().diff(moment(messageSentTime), 'seconds');
				setRecallRemainingTimeInSeconds(diffSecs);
				if (diffSecs <= 0) {
					stopRecallOption();
				}
			}, [stopRecallOption, messageSentTime]);

		useEffect(() => {
			if (align === 'right' && messageSentTime && moment().diff(moment(messageSentTime), 'minutes') <= recallMaxTimeInMins) {
				getRemainingTime();
				recallTimerIntervalRef && clearInterval(recallTimerIntervalRef.current);

				const id: any = setInterval(() => {
					if (messageSentTime && moment().diff(moment(messageSentTime), 'seconds') <= recallMaxTimeInMins * 60) {
						getRemainingTime();
					} else {
						stopRecallOption();
					}
				}, 1000);

				recallTimerIntervalRef.current = id;
				return () => {
					clearInterval(recallTimerIntervalRef.current);
				};
			} else {
				stopRecallOption();
			}
		}, [align, messageSentTime, recallTimerIntervalRef, getRemainingTime, stopRecallOption]);

		useEffect(() => {
			const element = msgCardContentRef?.current;

			if (element && element.offsetHeight > 0) {
				if (message?.messageState !== ChatMessageState.RESEND) {
					const offsetHeight = element.offsetHeight;
					const scrollHeight = element.scrollHeight;
					if (scrollHeight >= offsetHeight + 10) {
						setShouldShowReadMoreLess(true);
					}
					element.style.overflowY = 'hidden';
				}
			}
		}, [msgCardContentRef, msgCardContentRef?.current?.offsetHeight, message?.messageType]);

		const resendMessage = useCallback(async () => {
			setIsResendingMessage(true);
			await xmpp.sendMessage({
				messageBody: message.body,
				messageState: ChatMessageState.RESEND,
				messageType: message.messageType!,
				relatedMessageId: message.messageKey,
				conversationType: message.type,
				receiverId: message.username,
			});
			setIsResendingMessage(false);
		}, [message]);

		const deleteMessage = useCallback(async () => {
			setIsResendingMessage(true);
			setIsResendingMessage(false);
		}, []);

		useEffect(() => {
			let uploadMediaPubSubToken: any = null;
			if (message.status === MessageSendStatus.PendingUpload && (message.messageType === MessageType.MEDIA || message.messageType === MessageType.FILE)) {
				uploadMediaPubSubToken = PubSub.subscribe(PubSubEventType.UploadMedia, (msg: string, data: any) => {
					if (data.action === PubSubEventType.UploadMedia && data.constructor === Object && message?.messageKey === data?.messageKey) {
						setFileUploadingProgress(data.percentComplete);
					}
				});
			}

			return () => {
				if (uploadMediaPubSubToken) {
					PubSub.unsubscribe(uploadMediaPubSubToken);
				}
			};
		}, [message]);

		const isReplied = message.inReplyTo,
			repliedMessageData: Message | undefined = message.replyMessage;

		//For UI test purpose only, remove it after UI test done
		// message.messageType = ChatMessageState.AUDIO;
		// message.status = MessageSendStatus.SentFailed;
		//

		const isPendingUpload = useMemo(() => message.status === MessageSendStatus.PendingUpload, [message]),
			isPendingSent = useMemo(() => message.status === MessageSendStatus.PendingSent, [message]),
			isSent = useMemo(() => message.status === MessageSendStatus.Sent, [message]),
			sentFailed = message.status === MessageSendStatus.SentFailed,
			isReceived = useMemo(() => message.status === MessageSendStatus.Received, [message]),
			isMediaType = useMemo(() => message.messageType === MessageType.MEDIA || message.messageType === MessageType.FILE, [message]),
			isTextType = useMemo(() => message.messageType === MessageType.TEXT, [message]);

		const renderSnippet = () => {
			return translating || message.translated ? (
				<>
					{translating && <IonSpinner name="crescent" />}
					{message.translated && message.translation && (
						<span
							dangerouslySetInnerHTML={{
								__html: message.translation,
							}}
						></span>
					)}
				</>
			) : (
				snippet
			);
		};

		return (
			<div key={key}>
				<IonItemSliding
					className="thread-item-sliding"
					ref={ionItemSlideRef}
					key={message.messageKey}
					onIonDrag={(event) => {
						info('OnIonDrag ', event);
						if (event.detail.ratio === -1) {
							emojiClickHandler('reply');
							const itemRef: any = ionItemSlideRef?.current;
							if (itemRef) {
								itemRef.close();
							}
						}
					}}
				>
					<IonItem mode="ios" lines="none" className={'thread-container ' + (align === 'left' ? 'left' : 'right')} key={'thread-container' + (message.messageKey ? message.messageKey : Math.random())} detail={false} button={true}>
						<div className="item-inner-div">
							{isReplied && repliedMessageData && (
								<ThreadRepliedForView
									alignClassName={align === 'left' ? 'left' : 'right'}
									userMessageData={repliedMessageData}
									onClick={() => {
										onReplyItemSelect(repliedMessageData);
									}}
								/>
							)}

							<div className={'thread-item ' + (align === 'left' ? 'left' : 'right')}>
								<div className="mask-item">
									<IonImg src={ReplyArrowIcon} />
								</div>

								{selectingMessages && <IonCheckbox className={align === 'left' ? 'left' : ''} checked={isSelected} slot="start" onClick={() => opctionClick('check')} />}

								{isRecall || message.recalled ? (
									<div className="recall-message-view">
										<IonText>{align === 'right' ? locale.dashboard.thread.you_recalled_a_message : `${message.username} ${locale.dashboard.thread.recalled_a_message}`}</IonText>
										{recallRemainingTimeInSeconds > 0 && (
											<IonButton
												fill="clear"
												onClick={() => {
													onPublishData({
														type: PubSubEventType.RecallEdit,
														data: message,
													});
												}}
											>
												{locale.global.edit}
											</IonButton>
										)}
									</div>
								) : (
									<div className={align === 'left' ? 'gp-message-other' : 'gp-message-me'}>
										{isLoading ? (
											<IonSpinner style={{ marginRight: '20px' }} name="crescent" />
										) : (
											<>
												<div className={align === 'left' ? 'avatar-group' : 'avatar-group-me'}>
													<IonThumbnail onClick={onProfilePhotoPress}>
														<img
															style={{
																borderRadius: '3px',
																width: '40px',
																height: '40px',
															}}
															src={receiverProfilePhoto}
															alt="profilePicture"
														/>
													</IonThumbnail>
												</div>
												<div className="main-area-gp-me">
													{isGroup && senderProfileName !== 'Me' ? (
														<IonCardHeader style={{ padding: '0' }}>
															<IonCardSubtitle>
																<span
																	dangerouslySetInnerHTML={{
																		__html: senderProfileName?.replace('(', '(<span class="paranthesis">').replace(')', '</span>)'),
																	}}
																></span>
															</IonCardSubtitle>
														</IonCardHeader>
													) : null}
													<IonCard {...bind} ref={cardRef} onClick={cardClickHandler} className={align === 'left' ? 'group-message-left' : 'group-message-me'}>
														<IonCardContent
															ref={msgCardContentRef}
															style={{
																opacity: showEmojiPopover || showQuickFunctionPopover ? 0 : 1,
																maxHeight: !isTextType || showReadMore ? 'unset' : '382px',
															}}
															className={(isEmojiStyleApplied ? 'only-emoji' : '') + ' message-card-content'}
														>
															{align === 'left' && (
																<>
																	{isMediaType && message.media?.mediaType === ChatMediaType.AUDIO ? (
																		<div className="receiver-audio-type-msg-container">
																			{snippet}{' '}
																			<div className="audio-convert-to-text-btn">
																				<div className="red-dot"></div>
																				<IonButton fill="clear" className="convert-btn">
																					Convert to Text
																				</IonButton>
																			</div>
																		</div>
																	) : (
																		<>{renderSnippet()}</>
																	)}
																</>
															)}

															{align === 'right' && (
																<>
																	<>{renderSnippet()}</>
																	{isMediaType && isPendingUpload && (
																		<div className="media-loader-mask">
																			<CircularProgressbar
																				value={fileUploadingProgress}
																				strokeWidth={10}
																				styles={{
																					path: {
																						stroke: '#ffffff',
																						strokeLinecap: 'round',
																					},
																				}}
																			/>
																		</div>
																	)}
																</>
															)}

															{/* {isMediaType && message.media?.mediaType === ChatMediaType.VIDEO && (isSent || isReceived) && (
															<div className="media-loader-mask">
																<IonImg className="video-play-icon" src={VideoPlayIcon} />
															</div>
														)} */}
														</IonCardContent>

														{message.messageType === MessageType.TEXT && shouldShowReadMoreLess && (
															<span className="readmore-less" onClick={() => setShowReadMore(!showReadMore)}>
																{showReadMore ? locale.chat.read_less : locale.chat.read_more}
															</span>
														)}

														{(!isRecall || !message.recalled) && !message.linkPreview && (translating || message.translated) && <IonCardContent className="translatedtxt-card-content">{snippet}</IonCardContent>}
													</IonCard>
												</div>

												{align === 'right' && (
													<>
														{(isPendingSent || sentFailed) && (
															<div className="message-send-resend-view">
																{isTextType && isPendingSent && <CustomSpinner size={22} />}

																{sentFailed && (
																	<IonImg
																		className="failed-icon"
																		src={FailedIcon}
																		onClick={() => {
																			setShowResendMessageModal(true);
																		}}
																	/>
																)}
															</div>
														)}

														{isMediaType && message.media?.mediaType === ChatMediaType.VIDEO && isPendingUpload && (
															<div className="video-message-icon-view">
																<IonImg
																	className="video-pause-icon"
																	src={VideoMessageIcon}
																	onClick={() => {
																		// setShowResendMessageModal(true);
																	}}
																/>
															</div>
														)}
													</>
												)}
											</>
										)}
									</div>
								)}
							</div>
						</div>
					</IonItem>

					<ThreadItemOptions ionItemSlideRef={ionItemSlideRef} />
				</IonItemSliding>

				<EmojiPopup
					isGroup={isGroup}
					show={showEmojiPopover || showQuickFunctionPopover}
					popupType={showEmojiPopover ? 'emoji' : showQuickFunctionPopover ? 'quickFunction' : ''}
					onHide={() => {
						setShowEmojiPopover(false);
						setLongpressPopupIsEnlarge(false);
						setShowQuickFunctionPopover(false);
					}}
					align={align}
					isEnlarge={longpressPopupIsEnlarge}
					canRecall={recallRemainingTimeInSeconds > 0}
					canTranslate={canTranslate}
					selectedThreadElementPosition={selectedThreadElementPosition}
					snippet={snippet}
					receiverphoto={receiverProfilePhoto}
					receiverName={senderProfileName}
					topRowItems={topRowItems}
					bottomRowItems={bottomRowItems}
					onItemSelect={(item: any) => {
						setShowEmojiPopover(false);
						setShowQuickFunctionPopover(false);
						emojiClickHandler(item.type);
					}}
				/>

				<DeleteContact
					type=""
					userData={'deleteMessage'}
					show={showActionSheet}
					onDeleteCallBack={() => {
						showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Deleted', toastType: 'secondary' });
						setShowActionSheet(false);
					}}
					onCloseCallBack={() => {
						setShowActionSheet(false);
					}}
				/>

				{/* <IonActionSheet mode="ios" header={actionSheetHeaderTitle} isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass={'manage-contact-action-sheet '} buttons={actionsheetButtons}></IonActionSheet> */}

				<RecallPopupModal
					show={showRecallPopup}
					remainingTimeInSeconds={recallRemainingTimeInSeconds}
					onRecall={async () => {
						setShowRecallPopup(false);
						setIsLoading(true);

						await apiService.recallMessage({ ...message, recalled: 1 }).then(() => {
							setIsRecall(true);
							setIsLoading(false);
						});
					}}
					onCancel={() => {
						setShowRecallPopup(false);
					}}
				/>

				<ResendMessageModal
					show={showResendMessageModal}
					onCloseCallBack={() => {
						setShowResendMessageModal(false);
					}}
					onConfirm={() => {
						setShowResendMessageModal(false);
						resendMessage();
					}}
					onDelete={() => {
						setShowResendMessageModal(false);
						deleteMessage();
					}}
				/>
			</div>
		);
	}
);

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	showToastView: (payload: GlobalStateTypes) => dispatch(showToast(payload)),
	onPublishData: (payload: String) => dispatch(publishData(payload)),
	translateMessage: (payload: any) => dispatch(translateMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreadComponent);
