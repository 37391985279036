import { IonModal, IonToast } from '@ionic/react';
import React from 'react';
import './SelectFileModal.scss';
import SelectComponent from './SelectComponent';

interface SelectFileModalprops {
	show: boolean;
	type: string;
	onCloseCallBack: any;
	item: any;
	onToastCallBack: any;
}
interface iState {
	showToast: boolean;
	toastMessage?: string;
	text?: string;
}

class SelectFileModal extends React.Component<SelectFileModalprops, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			showToast: false,
		};
	}

	render() {
		return (
			<>
				<IonModal showBackdrop={true} backdropDismiss={true} isOpen={this.props.show} onDidDismiss={this.props.onCloseCallBack} swipeToClose={true} cssClass="select-file-modal" animated={false}>
					<div
						className="content"
						onClick={() => {
							this.props.onCloseCallBack && this.props.onCloseCallBack();
						}}
					>
						<div
							className="select-main"
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<div className="option-content">
								<>
									{this.props.type === 'folder' ? <img alt="" className="image" src={'../assets/icon/foldericon.svg'} /> : ''}

									{this.props.type === 'image' ? <img alt="" className="image" src={'../assets/icon/imageicon.svg'} /> : ''}

									{this.props.type === 'video' ? <img alt="" className="image" src={'../assets/icon/videoicon.svg'} /> : ''}

									{this.props.type === 'file' ? <img alt="" className="image" src={'../assets/icon/fileicon.svg'} /> : ''}
								</>

								<p>
									{this.props.type === 'folder' && this.props.item?.FolderName}
									{this.props.type === 'image' && this.props.item?.ImageName}
									{this.props.type === 'video' && this.props.item?.VideoName}
									{this.props.type === 'file' && this.props.item?.FileName}
								</p>
							</div>

							<SelectComponent
								useFor={'optionModal' || 'selection'}
								item={this.props.item}
								selectedTab={this.props.type}
								List={0}
								onCloseCallBack={() => {
									this.props.onCloseCallBack();
								}}
								onToastCallBack={({ msg, show }: any) => {
									this.setState({
										showToast: true,
										toastMessage: msg,
										text: show,
									});
								}}
							/>
						</div>
					</div>
				</IonModal>

				<IonToast
					// icon={informationCircle}

					duration={3000}
					cssClass="select-component-toast-view "
					isOpen={this.state.showToast}
					onDidDismiss={() => {
						this.setState({ showToast: false });
					}}
					message={this.state.toastMessage}
					position={'bottom'}
					buttons={[
						this.state.text === 'star'
							? {
									text: 'Undo',
									handler: () => {
										this.setState({ showToast: false });
									},
							  }
							: '',
					]}
				/>
			</>
		);
	}
}

export default SelectFileModal;
