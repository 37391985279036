import React, { createRef } from 'react';
import { login } from '../../redux/actions/auth';
import { IonButton, IonCheckbox, IonCol, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow } from '@ionic/react';
import './style.scss';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getInternalStorage, setInternalStorage } from '../../helpers/common';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';
import metadata from '../../metadata.json';
import { LocalStorageKeys, NavigationRoutes, SessionStorageKeys } from '../../services/enumService';
import { LoginRequestData } from '../../types/api-requests';
interface IProps {
	auth: any;
	login: Function;
	history: any;
}

type IState = {
	personalDeviceShowFullText: boolean;
	userId: String;
	password: String;
	personalDevice: Boolean;
	showPassword: boolean;
	isLoading?: boolean;
	loadingText?: string;
	version: any;
	errorMessage?: string;
};

class LoginComponent extends React.Component<IProps, IState> {
	userIdInputRef: any = createRef();
	passwordInputRef: any = createRef();
	personalDeviceRef: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	isLoginProcessing: Boolean = false;

	constructor(props: IProps) {
		super(props);

		this.state = {
			userId: '',
			password: '',
			personalDevice: false,
			personalDeviceShowFullText: false,
			showPassword: false,
			version: undefined,
		};
	}

	cookies: any = getInternalStorage();

	async setPageData() {
		this.cookies[LocalStorageKeys.PersonalDevice] = this.cookies[LocalStorageKeys.PersonalDevice] === 'undefined' ? false : this.cookies[LocalStorageKeys.PersonalDevice] || false;
		setInternalStorage(LocalStorageKeys.PersonalDevice, this.cookies[LocalStorageKeys.PersonalDevice]);
		this.cookies[SessionStorageKeys.Active] = false;
		setInternalStorage(SessionStorageKeys.Active, this.cookies[SessionStorageKeys.Active]);
		this.cookies[SessionStorageKeys.Version] = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;
		setInternalStorage(SessionStorageKeys.Version, this.cookies[SessionStorageKeys.Version]);
		this.cookies[LocalStorageKeys.DBVersion] = metadata.dbVersion;
		setInternalStorage(LocalStorageKeys.DBVersion, this.cookies[LocalStorageKeys.DBVersion]);
		this.setState({
			version: this.cookies[SessionStorageKeys.Version],
		});
	}

	async componentDidMount() {
		this.componentIsMounted = false;
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		await this.setPageData();
		this.componentIsMounted = true;
		const verficationStatus = localStorage.getItem(LocalStorageKeys.Verfication);
		if (!verficationStatus) {
			this.props.history.push(NavigationRoutes.VERIFICATIONS_REQUIRED);
		}
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.setPageData();
			this.componentIsUpdated = true;
		}
	}

	preLogin = () => {
		let { userId, password, personalDevice } = this.state;

		if (!userId || !password) {
			this.setState({
				errorMessage: locale.login.error.all_fields,
			});
		} else {
			if (!this.isLoginProcessing) {
				this.isLoginProcessing = true;
				this.setState({ errorMessage: '', isLoading: true });

				this.props.login(
					{
						username: userId ? userId.toLowerCase() : '',
						password,
						personalDevice,
					},
					(status: boolean, response: any) => {
						this.isLoginProcessing = false;

						if (status) {
							this.props.history.push(NavigationRoutes.TABS);

							setTimeout(() => {
								this.setState({ errorMessage: '', isLoading: false });
							}, 500);
						} else {
							this.setState({ errorMessage: response.error || response.error_description || response.message, isLoading: false });
						}
					}
				);
			}
		}
	};

	redirect() {
		this.props.history.push(NavigationRoutes.SIGNUP);
	}

	moveToNextField(event: any, nextFieldRef: any, callBack: any = null) {
		if (event.key === 'Enter' || event.key === 'Tab') {
			event.preventDefault();
			if (callBack) {
				callBack();
			}
			if (nextFieldRef) {
				nextFieldRef?.current.setFocus(true);
			}
		}
	}

	_renderFieldWarning(title: String, onClick: any = null) {
		return (
			<IonRow className="input-helper-row" onClick={onClick}>
				<IonCol>{title}</IonCol>
			</IonRow>
		);
	}

	_renderCopyRight = () => {
		return (
			<IonRow className="copyright-version-row">
				<IonCol>
					<div
						style={{
							fontSize: '12px',
							padding: '0 15px',
							margin: '0',
							marginBottom: '10px',
							color: '#585858',
							textAlign: 'center',
						}}
					>
						{locale.global.copyright}
						<div className="copyright-symbol">c</div>
						{locale.global.copyright_year}
						<div className="version">
							{locale.global.version_word} {this.state.version}
						</div>
					</div>
				</IonCol>
			</IonRow>
		);
	};

	_renderSignupButton = () => {
		return (
			<IonRow>
				<IonCol className="auth-register-button">
					<IonButton
						className="register-button"
						fill="clear"
						onClick={() => {
							this.props.history.push(NavigationRoutes.SIGNUP);
						}}
					>
						Register
					</IonButton>
				</IonCol>
			</IonRow>
		);
	};

	_renderLoginButton = (isLoginBtnEnable: boolean) => {
		return (
			<IonRow>
				<IonCol className="auth-button-col">
					<IonButton disabled={!isLoginBtnEnable} className="auth-button" color="#000000" type="submit" fill="solid" onClick={this.preLogin}>
						Log in
					</IonButton>
				</IonCol>
			</IonRow>
		);
	};

	_renderPersonalDeviceCheckbox = () => {
		const { personalDeviceShowFullText } = this.state;
		return (
			<>
				<IonItem lines="none" className="personal-device-checkbox-item">
					<IonCheckbox mode="ios" id="personalDevice" slot="start" color="dark" value="off" checked={this.cookies[LocalStorageKeys.PersonalDevice]} ref={this.personalDeviceRef}></IonCheckbox>
					<IonLabel>{locale.login.checkbox.personal_device}</IonLabel>
				</IonItem>

				<p className="personal-device-text">
					{locale.login.info.personal_device}
					{this.state.personalDeviceShowFullText && <span>&nbsp;{locale.login.info.learnMore}</span>}
					<span
						className="typography"
						onClick={() =>
							this.setState({
								personalDeviceShowFullText: !personalDeviceShowFullText,
							})
						}
					>
						&nbsp;
						{personalDeviceShowFullText ? locale.login.info.read : locale.login.info.learn_more}
					</span>
				</p>
			</>
		);
	};

	_renderInputField = () => {
		const { userId } = this.state;

		return (
			<IonItem className="auth-input-item" lines="none">
				<IonInput
					required
					/*value={String(userId)}*/
					placeholder={locale.login.placeholder.userId}
					id="userId"
					type="text"
					onIonChange={(e) => this.setState({ userId: e.detail.value! })}
					clearInput
					autofocus
					enterkeyhint={userId ? 'enter' : undefined}
					ref={this.userIdInputRef}
					onKeyUp={(event: any) => {
						this.moveToNextField(event, this.passwordInputRef);
					}}
				></IonInput>
				<IonLabel slot="end">@{locale.global.app_name}</IonLabel>
			</IonItem>
		);
	};

	_renderPasswordField = () => {
		const { userId, password, showPassword } = this.state;
		return (
			<IonItem className="auth-input-item top-space" lines="none">
				<IonInput
					required
					/*value={String(password)}*/
					placeholder={locale.login.placeholder.password}
					type={showPassword ? 'text' : 'password'}
					id="password"
					onIonChange={(e) => {
						this.setState({ password: e.detail.value! });
					}}
					onIonBlur={(e) => {}}
					clearInput
					ref={this.passwordInputRef}
					enterkeyhint={password ? 'done' : undefined}
					onKeyUp={(event: any) => {
						this.moveToNextField(event, null, () => {
							if (userId && password) {
								this.preLogin();
							}
						});
					}}
				></IonInput>

				{password && (
					<IonButton className="visibility-onoff-btn" fill="clear" onClick={() => this.setState({ showPassword: !showPassword })}>
						<IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
					</IonButton>
				)}
			</IonItem>
		);
	};

	render() {
		const { userId, password, isLoading, loadingText, errorMessage } = this.state;
		let isLoginBtnEnable = userId && password ? true : false;
		const verficationStatus = localStorage.getItem(LocalStorageKeys.Verfication);

		return !verficationStatus ? null : (
			<>
				{this.cookies[LocalStorageKeys.Uuid] ? (
					<Redirect to={NavigationRoutes.TABS} />
				) : (
					<IonPage className="auth-page">
						<IonContent className="auth-wrapper" fullscreen>
							<div className="inner-content">
								<div className="vertical-space-1" />

								<div className="be-society">
									<h1>{locale.global.app_name}</h1>
									<IonLabel>{locale.global.tagline}</IonLabel>
								</div>

								<div className="vertical-space-2" />

								<form className="formContainer">
									<IonList className="input-item-list">
										{errorMessage ? <p className="warnings">{errorMessage}</p> : null}
										<IonLabel className="auth-title">Log in</IonLabel>
										{this._renderInputField()}
										{this._renderFieldWarning(locale.login.warning.forgot_username)}

										{this._renderPasswordField()}
										{this._renderFieldWarning(locale.login.warning.forgot_password)}

										{this._renderPersonalDeviceCheckbox()}
									</IonList>
								</form>

								<div className="vertical-space-3" />

								{this._renderLoginButton(isLoginBtnEnable)}

								{this._renderSignupButton()}
								<div className="vertical-space-4" />

								{this._renderCopyRight()}
								<div className="vertical-space-5" />
							</div>
						</IonContent>

						{isLoading && <IonLoading cssClass={'login-loader'} mode="md" isOpen={true} message={loadingText || ''} />}
					</IonPage>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({ auth: state.auth });

const mapDispatchToProps = (dispatch: any) => ({
	login: (payload: LoginRequestData, callBack: Function) => dispatch(async () => await login(payload, callBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent as any);
